
import React, { useEffect } from 'react'
import Header from 'components/Cases/singleCases/Header'
import Consultants from 'components/Cases/singleCases/Consultants'
import Layout from 'components/Layout'
import Contact from 'components/Contact'
import About from 'components/Cases/singleCases/About'
import Video from 'components/Cases/singleCases/Video'
import Depositions from 'components/Cases/singleCases/Depositions'

const Index = () => {
    useEffect(() => {
      window.location.replace('/pt')
    }, [])
  
    return (
      <Layout>
      </Layout>
    )
  }
  

export default Index